<template>
  <v-container>
    <v-card elevation="8" outlined>
      <v-data-table :headers="headers" :items="vehicles" :items-per-page="5" :loading="loading" class="elevation-1">
        <template v-slot:top>
          <v-toolbar flat color="grey darken-3" class="white--text">
            <v-toolbar-title>Vehicle Manager</v-toolbar-title>
            <v-divider class="mx-4 white" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" min-width="400px" max-width="900px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined color="white" class="" v-bind="attrs" v-on="on" @click="resetValidation()"> Add Vehicle </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>
                <v-form ref="AddVehicleForm">
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="6" md="3">
                          <v-text-field
                            v-model="editedItem.year"
                            label="Year"
                            type="number"
                            :rules="[rules.required]"
                            :error-messages="errorFor('year')"
                          />
                        </v-col>
                        <v-col cols="6" md="3">
                          <v-text-field v-model="editedItem.make" label="Make" :rules="[rules.required]" :error-messages="errorFor('make')" />
                        </v-col>
                        <v-col cols="6" md="3">
                          <v-text-field v-model="editedItem.model" label="Model" :rules="[rules.required]" :error-messages="errorFor('model')" />
                        </v-col>
                        <v-col cols="6" md="3">
                          <v-text-field v-model="editedItem.name" label="Name" :rules="[rules.required]" :error-messages="errorFor('name')" />
                        </v-col>
                        <v-col cols="6" md="3">
                          <v-text-field
                            v-model="editedItem.begin_mileage"
                            label="Beginning Mileage"
                            type="number"
                            :error-messages="errorFor('begin_mileage')"
                          />
                        </v-col>
                        <v-col cols="6" md="3">
                          <v-text-field
                            v-model="editedItem.current_mileage"
                            label="Current Mileage"
                            type="number"
                            :rules="[rules.required]"
                            :error-messages="errorFor('current_mileage')"
                          />
                        </v-col>
                        <v-col cols="6" md="3">
                          <v-text-field
                            v-model="editedItem.average_mpg"
                            label="Average MPG"
                            type="number"
                            :error-messages="errorFor('average_mpg')"
                          />
                        </v-col>
                        <v-col cols="6" md="3">
                          <v-text-field v-model="editedItem.license" label="License Plate" :error-messages="errorFor('license')" />
                        </v-col>

                        <v-col cols="6" md="3">
                          <v-select
                            v-model="editedItem.color"
                            :items="colors"
                            clearable
                            label="Color"
                            :rules="[rules.required]"
                            :error-messages="errorFor('color')"
                          >
                            <template slot="item" slot-scope="data">
                              <v-sheet :color="data.item" width="200">{{ data.item }}</v-sheet>
                            </template>
                          </v-select>
                        </v-col>
                        <v-col cols="6" md="3">
                          <v-select v-model="editedItem.shade" :items="shades" clearable :disabled="!editedItem.color" label="Shade">
                            <template slot="item" slot-scope="data">
                              <v-sheet :color="`${editedItem.color} ${data.item}`" width="200">{{ data.item }}</v-sheet>
                            </template>
                          </v-select>
                        </v-col>
                        <v-col cols="6" md="3">
                          <v-select
                            v-model="editedItem.icon"
                            :items="icons"
                            :append-outer-icon="editedItem.icon"
                            label="Icon"
                            :rules="[rules.required]"
                            :error-messages="errorFor('icon')"
                          >
                            <template slot="item" slot-scope="data">
                              <v-icon :color="carColor">{{ data.item }}</v-icon> &nbsp;&nbsp;&nbsp;{{ data.item }}
                            </template>
                          </v-select>
                        </v-col>
                        <v-col cols="6" md="3">
                          <small>Color</small>
                          <v-sheet :color="carColor" class="text-center">
                            <p>{{ editedItem.color }} {{ editedItem.shade }}</p>
                          </v-sheet>
                        </v-col>

                        <v-row align="center" justify="center">
                          <v-col cols="8">
                            <v-file-input
                              v-model="editedItem.new_image"
                              accept="image/*"
                              label="Image"
                              :error-messages="errorFor('image')"
                            ></v-file-input>
                          </v-col>
                          <v-col cols="4">
                            <v-card>
                              <v-img :src="previewUploadImage" aspect-ratio="1"></v-img>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
                    <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
          </v-toolbar>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title></v-card-title>
              <v-card-text class="text-h5">Are you sure you want to delete this vehicle?</v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="black" text @click="closeDelete">Cancel</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="red darken-2" text @click="deleteItemConfirm">Delete</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
        <template v-slot:[`item.controls`]="{ item }">
          <v-btn @click="editItem(item)" icon>
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn @click="deleteItem(item)" icon>
            <v-icon>mdi-trash-can</v-icon>
          </v-btn>
        </template>
      </v-data-table>

      <!-- <v-overlay :value="loading" absolute>
        <v-progress-circular indeterminate color="primary" />
      </v-overlay> -->
    </v-card>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import errors from '@/shared/mixins/errors'

export default {
  name: 'AdminManageVehicles',
  components: {},
  mixins: [errors],
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      editedItem: {
        make: null,
        model: null,
        name: null,
        year: null,
        begin_mileage: null,
        current_mileage: null,
        average_mpg: null,
        icon: null,
        license: null,
        new_image: null,
        color: null,
        shade: null
      },
      defaultItem: {
        make: null,
        model: null,
        name: null,
        year: null,
        begin_mileage: null,
        current_mileage: null,
        average_mpg: null,
        icon: null,
        license: null,
        new_image: null,
        color: null,
        shade: null
      },
      editedIndex: -1,
      loading: true,
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Year', value: 'year' },
        { text: 'Make', value: 'make' },
        { text: 'Model', value: 'model' },
        { text: 'Controls', value: 'controls' }
      ],
      selectColor: {
        color: null,
        shade: null
      },
      defaultColor: {
        color: null,
        shade: null
      },
      colors: [
        'red',
        'pink',
        'purple',
        'deep-purple',
        'indigo',
        'blue',
        'light-blue',
        'cyan',
        'teal',
        'green',
        'light-green',
        'lime',
        'yellow',
        'amber',
        'orange',
        'deep-orange',
        'brown',
        'blue-grey',
        'grey',
        'black',
        'white'
      ],
      shades: [
        'lighten-1',
        'lighten-2',
        'lighten-3',
        'lighten-4',
        'lighten-5',
        'darken-1',
        'darken-2',
        'darken-3',
        'darken-4',
        'accent-1',
        'accent-2',
        'accent-3',
        'accent-4'
      ],
      icons: ['mdi-car-convertible', 'mdi-car-hatchback', 'mdi-van-passenger', 'mdi-car-pickup', 'mdi-car-side', 'mdi-car-sports', 'mdi-van-utility']
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.userData,
      vehicles: (state) => state.vehicle.vehicles
    }),
    rules() {
      return {
        required: (value) => !!value || 'Required'
      }
    },
    formTitle() {
      return this.editedIndex === -1 ? 'New Vehicle' : 'Edit Vehicle'
    },
    shadeDisabled() {
      return !this.selectColor.color
    },
    carColor() {
      const color = this.editedItem.color ? this.editedItem.color : ''
      const shade = this.editedItem.shade ? this.editedItem.shade : ''
      const result = `${color} ${shade}`
      return result
    },
    previewUploadImage() {
      if (this.editedItem.new_image) {
        return URL.createObjectURL(this.editedItem.new_image)
      } else if (this.editedItem.image) {
        return process.env.VUE_APP_STORAGE_URL + this.editedItem.image
      } else {
        return ''
      }
    }
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    }
  },
  created() {
    this.getData()
    this.$store.commit('setErrors', {})
  },
  methods: {
    ...mapActions('vehicle', ['fetchVehicles', 'addVehicle', 'delVehicle', 'patchVehicle']),
    async getData() {
      this.loading = true
      if (this.vehicles.length === 0) {
        await this.fetchVehicles()
      }
      this.loading = false
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    resetValidation() {
      if (this.$refs.AddVehicleForm) {
        this.$refs.AddVehicleForm.resetValidation()
      }
    },
    editItem(item) {
      this.resetValidation()
      this.editedIndex = this.vehicles.indexOf(item)
      this.editedItem = Object.assign({}, item)
      const color = this.editedItem.color ? this.editedItem.color.split(' ') : ''
      if (color.length > 1) {
        this.editedItem.color = color[0]
        this.editedItem.shade = color[1]
      }
      this.dialog = true
    },
    deleteItem(item) {
      this.editedIndex = this.vehicles.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    deleteItemConfirm() {
      // this.vehicles.splice(this.editedIndex, 1)
      this.delVehicle(this.editedItem)
      this.closeDelete()
    },
    async save() {
      this.$store.commit('setErrors', {})
      if (this.$refs.AddVehicleForm.validate()) {
        this.loading = true
        const data = new FormData()
        data.append('year', Number(this.editedItem.year))
        data.append('make', String(this.editedItem.make))
        data.append('model', String(this.editedItem.model))
        data.append('name', String(this.editedItem.name))
        data.append('current_mileage', Number(this.editedItem.current_mileage))
        data.append('color', String(this.carColor))
        data.append('icon', String(this.editedItem.icon))
        this.editedItem.begin_mileage ? data.append('begin_mileage', Number(this.editedItem.begin_mileage)) : null
        this.editedItem.average_mpg ? data.append('average_mpg', Number(this.editedItem.average_mpg)) : null
        this.editedItem.license ? data.append('license', String(this.editedItem.license)) : null
        this.editedItem.new_image ? data.append('image', this.editedItem.new_image) : null
        this.editedItem.id ? data.append('id', this.editedItem.id) : null

        if (this.editedIndex > -1) {
          try {
            await this.patchVehicle(data)
            const isErrors = Object.keys(this.errors).length > 0
            if (!isErrors) {
              this.close()
              this.$refs.AddVehicleForm.resetValidation()
            }
            this.loading = false
          } catch (error) {
            this.loading = false
          }
        } else {
          try {
            await this.addVehicle(data)
            const isErrors = Object.keys(this.errors).length > 0
            if (!isErrors) {
              this.close()
              this.$refs.AddVehicleForm.resetValidation()
            }
            this.loading = false
          } catch (error) {
            this.loading = false
          }
        }
      }
    }
  }
}
</script>

<style></style>
